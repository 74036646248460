<template>
    <div>
      <van-image :src='img'></van-image>
      <van-field v-model='name' class='name'></van-field>
      <div class='link'>
        <van-button type='primary' @click='onCopyLink' >复制链接</van-button>
      </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'
import Clipboard from 'clipboard'

Vue.use(Toast)

export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      img: '',
      name: '',
      link: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    const query = this.$route.query
    console.log(query)

    this.img = query.img
    this.name = query.name
    this.link = query.link
  },
  mounted () {},
  methods: {
    onCopyLink (e) {
      // console.log(this.link)
      const clipboard = new Clipboard(e.target, { text: () => this.link })
      clipboard.on('success', e => {
        Toast.success('已复制链接')
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        Toast.success('复制失败')
        clipboard.destroy()
      })
    }
  }
}
</script>

<style scoped lang="less">
.link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  font-size: 16px;
  color: black;
}
</style>
